* {
  margin: 0;
  padding: 0;
  font-family: "DotGothic16", sans-serif;
}

body {
  background-color: black;
}

.load-window {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0, 0, 74);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-window > div {
  width: 300px;
}
.load-window p {
  text-align: center;
  margin: 30px 0;
}
.load-window .logo-img {
  width: 100%;
  height: auto;
  aspect-ratio: 633/120;
}
.title {
  animation: glow 5s ease-in-out infinite;
  text-align: center;
  font-size: 48px;
  line-height: 120px;
  font-weight: bold;
}

.cloud-save-button {
  transition: box-shadow 0.5s;
  position: absolute;
  color: black;
  top: 10px;
  left: 10px;
}
.cloud-save-button:hover {
  box-shadow: none !important;
}
.load-window > div button {
  transition: box-shadow 0.5s;
  border: none;
  color: black;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  width: calc(100% - 10px);
  margin: 5px;
  height: 50px;
}
.load-window button:focus,
.load-window button:hover {
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 20px white;
}
.load-window > div > .delete-savedata {
  height: 30px;
  color: red;
}

main {
  padding: 0 20px;
  transition: filter 1s;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: pink;
  font-family: sans-serif;
}

.day {
  filter: invert(0);
  background-color: rgb(223, 245, 255);
}
.night {
  filter: invert(1);
  background-color: rgb(253, 255, 242);
}
.light {
  filter: invert(0);
  background-color: rgb(210, 211, 194);
}
.rainy {
  filter: invert(0);
  color: white;
  background-color: rgb(66, 67, 134);
}

.night img {
  filter: invert(1);
}

.controller {
  display: none;
  height: 100px;
  margin-top: 15px;
  width: 300px;
}
.controller > button {
  color: black;
  border: solid 1px black;
  background-color: white;
  border-radius: 0;
  height: 40px;
  margin: 5px;
}
.controller > .large {
  width: 290px;
}
.controller > .medium {
  width: 140px;
}
.controller > .mini {
  width: 50px;
}

.message {
  display: block;
  margin-top: 30px;
  padding: 10px;
  white-space: pre-wrap;
  height: 90px;
  background-color: rgba(193, 193, 193, 0.313);
  outline: double 8px black;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.debug {
  /* display: none; */
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 10px);
  overflow-wrap: break-word;
  font-size: 10px;
  background-color: white;
  opacity: 0.6;
}
.debug:hover {
  opacity: 1;
}

.fadeIn {
  animation: fadeIn 2s;
}
.fadeOut {
  opacity: 0;
  animation: fadeIn 2s reverse;
}
.blur {
  background-color: transparent;
  backdrop-filter: blur(10px);
}
.black {
  background-color: black;
}



@media screen and (max-width: 650px) {
  .controller {
    display: block;
  }
  .message {
    font-size: 12px;
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 0 skyblue;
  }
  50% {
    text-shadow: 0 0 20px skyblue;
  }
  1000% {
    text-shadow: 0 0 0 skyblue;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 1;
  }
}
